<template>
  <div class="lineLog">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>日志列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>修改内容:</span>
      <el-select v-model="list.logType" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="gettravelSpecsLog()">查找</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        align="center"
        prop="hotelLogId"
        label="日志id"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="strType"
        label="修改内容"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="masterName"
        label="修改人"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="hotelAddTime"
        label="修改时间"
        width="width"
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="$router.push(`/lineLogDetails/${row.hotelLogId}`)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { travelSpecsLog } from "../../api/travel";
export default {
  name: "travelSpecsLog",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {},
      options: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "新增",
        },
        {
          value: 2,
          label: "上下架",
        },
        {
          value: 3,
          label: "主标题",
        },
        {
          value: 4,
          label: "副标题",
        },
        {
          value: 5,
          label: "所属分类",
        },
        {
          value: 6,
          label: "展示图",
        },
        {
          value: 7,
          label: "轮播图",
        },
        {
          value: 8,
          label: "第三晚入住房间Id",
        },
        {
          value: 9,
          label: "评分",
        },
        {
          value: 10,
          label: "行程天数",
        },
        {
          value: 11,
          label: "排序",
        },
        {
          value: 12,
          label: "详情",
        },
        {
          value: 13,
          label: "是否可退订",
        },
        {
          value: 14,
          label: "退订时间1",
        },
        {
          value: 15,
          label: "退订比例1",
        },
        {
          value: 16,
          label: "退订时间2",
        },
        {
          value: 17,
          label: "退订比例2",
        },
        {
          value: 18,
          label: "退订时间免手续费",
        },
        {
          value: 19,
          label: "行程安排",
        },
        {
          value: 20,
          label: "费用须知",
        },
        {
          value: 21,
          label: "退改说明",
        },
      ],
      list: {
        travelId: Number(this.id),
        currentPage: 1,
        pageSize: 5,
        logType: null,
      },
    };
  },
  created() {
    this.gettravelSpecsLog();
  },
  methods: {
    async gettravelSpecsLog() {
      const { data } = await travelSpecsLog(this.list);
      console.log(data, "rizhi ");
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    handleSizeChange(size) {
      this.list.pageSize = size;
      this.gettravelSpecsLog();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.gettravelSpecsLog();
    },
  },
};
</script>
<style lang="less" scoped>
.lineLog {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-select {
      margin: 0 40px 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>